/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you experiencing the early stages of hearing loss in Washington State and are concerned about finding a solution? If so, don’t worry! You’re far from alone, and help is at hand."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you’re the kind of person who loves to make the most of Seattle’s vibrant music scene, or prefer the sound of waves crashing at Alki Beach, there’s a device out there to handle your hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With hearing aids, you can maintain an active lifestyle and experience life to the fullest, wherever you happen to be in the Evergreen State."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "losing-your-hearing-is-surprisingly-easy-in-washington-state",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#losing-your-hearing-is-surprisingly-easy-in-washington-state",
    "aria-label": "losing your hearing is surprisingly easy in washington state permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Losing your hearing is surprisingly easy in Washington State"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modern life can be hard on our ears, especially over time. There are plenty of ways we can damage our hearing if we aren’t careful. Just think about the hazards we encounter every day in Washington State. If you’re a resident of an urban area around Puget Sound, you’ve experienced the noise caused by highway and street traffic: from engines and horns, to the sounds of heavy machinery used by road crews."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Years of living in cities like Tacoma or Seattle cause hearing damage to build up – something many of us aren’t aware of until our hearing starts to fade. And that damage can be exacerbated by other sources of loud noises. For instance, Seattle is famous for its thriving music scene. If you’re a frequent patron of concerts or you’ve worked at local venues, you’re more likely to experience hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Work is another prime source of noise: from jackhammers pounding at construction sites, to airplane noise at Seattle-Tacoma International Airport. But rural areas aren’t without their own risks as well. The lumber industry is one of the noisiest around, and workers in the lumber yard tend to report hearing loss more than average. Additionally, hunting is another major hazard— just one close gunshot can cause permanent hearing damage!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One 2016 study found that 95% of American hunters had not worn any ear protection in the previous year. As you can see, it’s very easy to sustain hearing damage in Washington State."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-extent-of-hearing-loss-in-washington-state",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-extent-of-hearing-loss-in-washington-state",
    "aria-label": "the extent of hearing loss in washington state permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The extent of hearing loss in Washington State"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These hazards explain why so many people across the state experience hearing loss. In one 2008 survey, 260,000 Washington State residents reported having “serious hearing difficulties” (including over 130,000 below the age of 65). And the CDC reports that around 20% of those aged 18 and over in Washington State have some form of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "That’s slightly above the American average – a reflection of the unique threats that locals experience. What this shows is that hearing loss isn’t rare. Instead, it’s something that people in your neighborhood struggle with on a daily basis, including those under the age of 60."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "On average, Americans begin wearing hearing aids at the age of 70, while only 16% of those with hearing loss under 70 choose to wear them. We at hear.com want to change that and restore Washington’s hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "find-the-perfect-hearing-aid-with-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-the-perfect-hearing-aid-with-hearcom",
    "aria-label": "find the perfect hearing aid with hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find the perfect hearing aid with hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com works across the United States, providing much needed help to those affected by hearing loss. In Washington State, we’ve created a network of hearing aid experts and over 80 Partner Providers (hearing specialists and audiologists) to help you find the perfect hearing aids to match your lifestyle and needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our Partner Providers are located all over the state – from Olympia to Spokane—so no matter where you live, you’ll have a local professional to guide you through the purchasing and fitting process. With so many different hearing aid models on the market, it can be tricky for first-time wearers to find their ideal device, but don’t fret! hear.com is here to guide you every step of the way."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-hearcom-today-to-solve-your-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-hearcom-today-to-solve-your-hearing-loss",
    "aria-label": "contact hearcom today to solve your hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact hear.com today to solve your hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What are you waiting for? Take the first step and contact our team today. Simply fill out our online questionnaire and you’ll receive a free, personal phone consultation with one of our hearing aid experts."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After that, we’ll schedule an appointment for you with your local Partner Provider for a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " and hearing aid fitting. You’ll be amazed by the difference a hearing aid can make, and you’ll wonder why you didn’t act sooner. With hear.com, crystal clear hearing is within your reach, and we can’t wait to help."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
